div {
  display: flex;
  flex-direction: column;
}
input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: black;
  max-width: 100%;
}
button {
  border: solid;
  border-radius: 0;
  border-color: black;
  border-width: 2px;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  background-color: white;
  color: black;
}

.App {
  height: 100%;
  width: 100%;
}
.container {
  max-width: 800px;
  width: 100%;
}

.form-container {
  width: 80%;
  max-width: 650px;
}
.text-contained {
  width: 50%;
}
.full-width {
  width: 100%;
}
.row {
  flex-direction: row;
  flex-wrap: wrap;
}
.big-button {
  padding: 0.8rem 2rem;
  margin: 2.5rem;
  color: black;
  font-size: 2rem;
}
.disabled {
  opacity: 0.4;
}
.align-center {
  align-self: center;
}
.blog-like {
  margin-left: 32px;
  margin-right: 32px;
}
.margin-center {
  margin: 0 auto;
}
.align-start {
  align-self: flex-start;
}
.align-items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-space {
  justify-content: space-between;
}

.main {
  height: 100%;
  width: 100%;
}
.timer {
}
.box {
  box-sizing: border-box;
  border: solid;
  border-color: black;
  border-width: 2px;
  width: 50%;
}
.highlighted {
  box-shadow: 0px 3px 5px #ccc;
  transform: scale(1.01);
}
.timer-count {
  text-align: center;
  margin: 1.5rem;
}
.list-item {
  /* flex-direction: row; */
  justify-content: space-between;
  margin: 0.5rem;
  margin-left: 25%;
  padding: 0 1rem;
}
.complete-tick {
  height: 1.2rem;
  align-self: center;
}
.highlight {
  transform: scale(1.025);
  box-shadow: 0px 2px 2px #eaeaea;
}

/* Positions the form in the middle of the modal and ensures the content doesn't jump as status messages get added/removed */
.mc__form-container {
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  transition: height 0.2s ease;
}
/* Positions Text and form fields nicely in the middle with some spacing. */
.mc__form {
  display: grid;
  grid-template-rows: min-content min-content;
  grid-row-gap: 40px;
  justify-content: stretch;
  align-items: center;
  transition: height 0.2s ease;
  padding-left: 25%;
  padding: 10%;
  margin: 10%;
  margin-top: 1%;

}


.media-info {
  width: 720px;
  margin: 0 auto;
}
.video-player {
  position: relative;
  width: 720px;
  height: 405px;
  z-index: 0;
}
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.data-buttons {
  position: fixed;
  right: 0;
  height: 100%;
  width: 18%;
  margin-right: 2rem;
}
@media only screen and (max-width: 1024px) {
  .data-buttons {
    margin: auto;
    padding: 2rem;
    position: relative;
    width: 300px;
  }
}
